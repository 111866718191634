import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import SimpleSlider from "../components/slick-slider";
import Section from "../components/Section";
import Suites from "../components/Suites";
import Gallery from "../components/Gallery";
import PropTypes from "prop-types";

const IndexPage = ({ handleClick }) => (
  <Layout>
    <SEO title="Home" />
    <div className="bigPadding">
      <div className="sliderhero">
        <SimpleSlider />
      </div>
    </div>

    <Suites title="Available Suites" layout="full" />
    <Gallery title="Gallery" />
  </Layout>
);

export default IndexPage;
